module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":"213526412582335"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-65987015-2","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

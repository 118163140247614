import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyBLGzt84_npJ6hI4qUxwyUohD5bv1UBzuQ',
  authDomain: 'fooking-echo.firebaseapp.com',
  databaseURL: 'https://fooking-echo.firebaseio.com',
  projectId: 'fooking-echo',
  storageBucket: '',
  messagingSenderId: '985261910019',
  keyServer: 'AAAA5WYvqAM:APA91bFfGw9S1gXp-G6OfHHyqB2OH7t5ycYd_VKC87yF-FQl4mT5rOSBPEbBe8mYQx5dcONJfjTDEqbRmb93S9dyjA3RTcgKJP2miViglNK8cjyG0cP5d-MtnChx8uQqkhBJAbmaS8m0',
};

class Messaging {
  constructor() {
    firebase.initializeApp(config);
    this.topic = 'echoooooooooo';

    try {
      this.messaging = firebase.messaging();
    } catch (e) {
      console.log('Unable to Instantiate Firebase Messaing', e);
    }
  }

  requestPermission() {
    try {
      this.messaging.requestPermission().then(() => {
        this.receiveToken();
      }).catch(() => {});
    } catch (e) {
      console.log('Unable to request Permission', e);
    }
  }

  subscribeTokenToTopic(token) {
    const subscribeURL = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${this.topic}`;
    axios.post(subscribeURL, {}, {
      headers: {
        Authorization: 'key='.concat(config.keyServer),
      },
    });
  }

  receiveToken() {
    try {
      this.messaging.getToken()
        .then((currentToken) => {
          this.subscribeTokenToTopic(currentToken);
        });
    } catch (e) {
      console.log('Unable to get Token', e);
    }
  }

  refreshToken() {
    try {
      this.messaging.onTokenRefresh(() => {
        this.receiveToken();
      });
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }
}

export default Messaging;

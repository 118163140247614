import React from 'react';
import { Provider } from 'react-redux';
import Messaging from './src/services/messaging';
import createStore from './src/store';

const store = createStore();

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    const messaging = new Messaging();
    messaging.requestPermission();
  }
};

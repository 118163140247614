// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-home-jsx": () => import("/opt/build/repo/src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-video-jsx": () => import("/opt/build/repo/src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */),
  "component---src-templates-page-jsx": () => import("/opt/build/repo/src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-category-jsx": () => import("/opt/build/repo/src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-playlist-jsx": () => import("/opt/build/repo/src/templates/playlist.jsx" /* webpackChunkName: "component---src-templates-playlist-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")


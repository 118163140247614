export const ACTIVE_MENU = 'TOGGLE_MENU';
export const MENU_SELECTED = 'MENU_SELECTED';
export const SEARCH_VIDEO = 'SEARCH_VIDEO';
export const RESET_HEADER_STATE = 'RESET_HEADER_STATE';

/* ==================
 | HEADER ACTIONS
 ================== */
export const activeMenu = trigger => ({
  type: ACTIVE_MENU,
  trigger,
});

export const selectMenu = index => ({
  type: MENU_SELECTED,
  index,
});

export const searchVideo = trigger => ({
  type: SEARCH_VIDEO,
  trigger,
});

export const resetHeaderState = () => ({
  type: RESET_HEADER_STATE,
});

import {
  ACTIVE_MENU,
  MENU_SELECTED,
  SEARCH_VIDEO,
  RESET_HEADER_STATE,
} from '../actions';

const initialState = {
  isActiveMenu: false,
  menuSelected: -1,
  isSearch: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_MENU:
      return Object.assign({}, state, { isActiveMenu: action.trigger });
    case MENU_SELECTED:
      if (state.menuSelected === action.index) {
        return Object.assign({}, state, { menuSelected: -1 });
      }
      return Object.assign({}, state, { menuSelected: action.index });
    case SEARCH_VIDEO:
      return Object.assign({}, state, { isSearch: action.trigger });
    case RESET_HEADER_STATE:
      return Object.assign({}, state, {
        isActiveMenu: false,
        isSearch: false,
      });
    default:
      return state;
  }
};
